import queryString from 'query-string';
import { AddOpportunityToCandidateDto } from '../../opportunities/Opportunities/dtos/AddOpportunityToCandidateDto';
import { QuitOpportunityToCandidateDto } from '../../opportunities/Opportunities/dtos/QuitOpportunityToCandidateDto';
import { CreateUserDto } from '../../users/dtos/CreateUserDto';
import { UpdateUserDto } from '../../users/dtos/UpdateUserDto';
import User from '../../users/models/User';
import api from '../../_shared/services/api';
import { Paginate, Result } from '../../_shared/types/api.types';
import { ListCandidateDto } from '../dtos/ListCandidateDto';
import { ResendConfirmEmailDto } from '../dtos/ResendConfirmEmailDto';
import { UpdateCurriculumDto } from '../dtos/UpdateCurriculumDto';

class CandidateService {
  public async create(createUserDto: CreateUserDto): Promise<Result<User>> {
    const data = JSON.stringify(createUserDto);

    const formData = new FormData();
    formData.append('data', data);
    if (createUserDto.candidate && createUserDto.candidate.curriculumPdf)
      formData.append('files', createUserDto.candidate.curriculumPdf[0]);

    const response = await api({
      method: 'POST',
      url: '/users',
      data: formData,
      maxContentLength: Infinity,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }

  public async update(updateUserDto: UpdateUserDto): Promise<Result<User>> {
    const response = await api.patch('/users', updateUserDto);
    return response.data;
  }

  async changeStatus(userId: string, status: string): Promise<Result<null>> {
    const response = await api.put(`/users/candidates/${userId}/status`, { status });
    return response.data;
  }

  async candidateProfileAssociation(
    userId: string,
    hiringProfileId: string
  ): Promise<Result<null>> {
    const response = await api.patch(`/associate-candidate-profile/${userId}`, { hiringProfileId });
    return response.data;
  }

  public paginateCandidate = async ({
    ...listUsersCandidateDto
  }: ListCandidateDto): Promise<Paginate<User>> => {
    const queryParams = queryString.stringify(listUsersCandidateDto);
    const response = await api.get(`/users/candidates?${queryParams}`);
    return response.data.data;
  };

  public async resumeDownload(userId: string): Promise<any> {
    try {
      const response = await api.get(`/users/candidates/${userId}/resume`, {
        responseType: 'blob'
      });
      return response.data;
    } catch (errorResponse: any) {
      const data = errorResponse?.response?.data;
      if (data instanceof Blob) {
        const blobText = await data.text();
        const convertBlobTextInObj = JSON.parse(blobText);
        errorResponse.response.data = convertBlobTextInObj;
        throw errorResponse;
      }
    }
  }

  public async validateEmail(verifyCode: string): Promise<Result<void>> {
    const response = await api.post('/users/confirm-email', { verifyCode: verifyCode });
    return response.data;
  }

  public async resendConfirmEmail(
    resendConfirmEmailDto: ResendConfirmEmailDto
  ): Promise<Result<void>> {
    const response = await api.post('/users/resend-confirm-email', resendConfirmEmailDto);
    return response.data;
  }

  public async addOpportunityToCandidate(
    addOpportunityToCandidateDto: AddOpportunityToCandidateDto
  ): Promise<Result<User>> {
    const response = await api.put(
      '/users/candidates/link-opportunity',
      addOpportunityToCandidateDto
    );
    return response.data;
  }

  public async quitOpportunityToCandidate(
    quitOpportunityToCandidateDto: QuitOpportunityToCandidateDto
  ): Promise<Result<User>> {
    const response = await api.put(
      `/users/candidates/remove-opportunity/${quitOpportunityToCandidateDto.opportunityId}`,
      quitOpportunityToCandidateDto
    );
    return response.data;
  }

  public async updateCurricumPdf(updateCurriculumDto: UpdateCurriculumDto): Promise<Result<User>> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(updateCurriculumDto.filename));
    formData.append('files', updateCurriculumDto.curriculumPdf);

    const response = await api({
      method: 'PUT',
      url: '/users/candidates/curriculum-pdf',
      data: formData,
      maxContentLength: Infinity,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  }
}

export default new CandidateService();
