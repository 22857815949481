import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { OptionSelect } from '../../../../components/FormComponents/SelectForm';
import ConfirmModal from '../../../../components/ModalComponents/ConfirmModal';
import Pagination from '../../../../components/Paginate/Pagination';
import { JobsProfile } from '../../../jobsProfile/models/JobsProfile';
import JobsProfileService from '../../../jobsProfile/service/JobsProfileService';
import User from '../../../users/models/User';
import userService from '../../../users/service/userService';
import {
  getErrorMessage,
  manageApiErrorResponse
} from '../../../_shared/helpers/handleApiErrorResponse';
import { translateLevel } from '../../../_shared/helpers/LevelHelper';
import { Paginate } from '../../../_shared/types/api.types';
import { ListCandidateDto } from '../../dtos/ListCandidateDto';
import candidateService from '../../service/candidateService';
import CareersTable from './components/CareersTable';
import FilterCareers from './components/FilterCareers';
import ImagePreviewModal from './components/ImagePreviewModal';

const ListCareers = () => {
  const [filterActive, setFilterActive] = useState<ListCandidateDto>({});
  const [downloadCandidate, setDownloadCandidate] = useState('');
  const [candidatesPaginate, setCandidatesPaginate] = useState<Paginate<User>>();
  const [optionsJobProfile, setOptionsJobProfile] = useState<OptionSelect[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [itemActive, setItemActive] = useState<any>({});
  const [file, setFile] = useState<any>();
  const [urlFile, setUrlFile] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);

  const loadCandidates = async () => {
    const result = await candidateService.paginateCandidate({
      ...filterActive,
      limit: 10
    });
    setCandidatesPaginate(result);
  };

  const handleChangeFilter = (filterValue: ListCandidateDto) => {
    setFilterActive(filterValue);
  };

  const onChangePage = async (page: number) => {
    setFilterActive((old) => ({ ...old, page }));
  };

  const handleClickResumeDownload = async (userId: string) => {
    setDownloadCandidate(userId);
    try {
      const result = await candidateService.resumeDownload(userId);
      setFile(result);
      setIsOpen(true);
      setDownloadCandidate('');
    } catch (resultError) {
      handleErrors(resultError);
      setDownloadCandidate('');
    }
  };

  function closeModal() {
    setIsOpen(!isOpen);
    setFile(undefined);
    setUrlFile(undefined);
  }

  const handleClickResumeDownloadPdf = async (user: User) => {
    const urlImage = user.candidate?.curriculumPdf?.completeName;
    if (!urlImage) {
      return;
    }
    try {
      const result = await userService.getImageUrl(urlImage);
      setUrlFile(result);
      setIsOpen(true);
    } catch (resultError) {
      handleErrors(resultError);
    }
  };

  const handleClickChangeStatus = async (userId: string, status: string) => {
    try {
      await candidateService.changeStatus(userId, status);
      loadCandidates();
    } catch (resultError) {
      handleErrors(resultError);
    }
  };

  const handleChangeHiringProfile = async (hiringProfileSelected: any, userId: string) => {
    try {
      await candidateService.candidateProfileAssociation(userId, hiringProfileSelected);
      loadCandidates();
    } catch (resultError) {
      handleErrors(resultError);
    }
  };

  const handleDeleteCandidate = async () => {
    try {
      const result = await userService.deleteUser(itemActive);
      toast.success(result.message);
      loadCandidates();
    } catch (resultError) {
      handleErrors(resultError);
    } finally {
      setOpenConfirmModal(false);
    }
  };

  const handleClickDeleteCandidate = (userId: string) => {
    setItemActive(userId);
    setOpenConfirmModal(true);
  };

  const handleErrors = (resultError: unknown) => {
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const error = getErrorMessage(resultErrorResponse);
    toast.error(error);
  };

  const getOptionsJobProfile = async () => {
    const result = await JobsProfileService.paginateJobsProfile({
      ...filterActive,
      limit: 999
    });
    let allJobProfiles: JobsProfile[] = [];
    if (!result?.results) return;
    for (const group of result.results) {
      if (group.jobProfiles && Array.isArray(group.jobProfiles)) {
        allJobProfiles = allJobProfiles.concat(group.jobProfiles);
      }
    }

    if (allJobProfiles.length > 0) {
      const optionsJobProfiles = allJobProfiles.map((jobProfile) => {
        const lavelPt = translateLevel(jobProfile.level);
        return {
          value: jobProfile.id,
          label: jobProfile.name + ' ' + (lavelPt ? lavelPt.pt : '')
        };
      });

      setOptionsJobProfile(optionsJobProfiles);
    }
  };

  useEffect(() => {
    loadCandidates();
  }, [filterActive]);

  useEffect(() => {
    getOptionsJobProfile();
  }, []);

  return (
    <div className="pt-16">
      <h1 className="text-3xl font-semibold text-teal-600 mb-8">Painel Carreiras</h1>

      <div className="mt-2">
        <FilterCareers onChange={handleChangeFilter} optionsJobProfile={optionsJobProfile} />
      </div>

      <div className="mt-8">
        <CareersTable
          onChangeHiringArea={handleChangeHiringProfile}
          users={candidatesPaginate?.results}
          onClickDownload={handleClickResumeDownload}
          onClickDownloadPdf={handleClickResumeDownloadPdf}
          downloadCandidate={downloadCandidate}
          onClickChangeStatus={handleClickChangeStatus}
          onClickDeleteCandidate={handleClickDeleteCandidate}
          optionsJobProfile={optionsJobProfile}
        />

        <div className="mt-4">
          <Pagination
            currentPageLength={candidatesPaginate?.results.length}
            page={candidatesPaginate?.currentPage}
            pageSize={candidatesPaginate?.limit}
            onChangePage={onChangePage}
            totalItems={candidatesPaginate?.totalItems}
          />
        </div>
      </div>
      <ConfirmModal
        isOpen={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        modalText="Deseja excluir esse candidato?"
        cancelButton={{
          buttonText: 'Cancelar',
          onClick: () => setOpenConfirmModal(false)
        }}
        confirmButton={{
          buttonText: 'Confirmar',
          onClick: handleDeleteCandidate
        }}
      />
      <ImagePreviewModal isOpen={isOpen} onClose={closeModal} file={file} urlFile={urlFile} />
    </div>
  );
};

export default ListCareers;
